import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import '../../stylesheets/ReactModalPortal.scss'
import { getCsrfToken } from '../../utilities/utilities.ts'
import sendToPhoneIcon from '../../../assets/images/send_to_phone_icon.svg'
import rubyConstants from '../../ruby_constants.js'
import {
  useCollectionSelectionCheckboxes,
  getCheckedCollectionSelectionIds,
} from '../../utilities/active_admin_collection_select_helper.js'
import usStates from '../../utilities/us_states.js'
import LoadinDotsFullScreen from '../LoadinDotsFullScreen.jsx'
import {
  OrgSelectInput,
  OrgPhoneInput,
} from '../../views/navigator_support_orgs/inputs/OrgInputs.jsx'
import GenericModal from '../GenericModal.jsx'
import LinkSharingFormClinicOptions from './LinkSharingFormClinicOptions.jsx'
import './LinkSharingForm.scss'
import { TooltipAnchor, Tooltip } from '../Tooltip.jsx'

const recordTypes = {
  NavigatorClinic: {
    explainerText:
      'Upon tapping the link, recipient will see the following for each provider option you’ve chosen to share: provider name; website URL; phone; address and maps link; hours of operation (if available); appointment availability estimates for procedure appointments, pill appointments, and counseling appointments, if available/relevant; and provider link on AbortionFinder.org.',
    recordDisplayName: 'Provider',
    showLinkSharingFormClinicOptions: true,
  },
  NavigatorSupportOrg: {
    explainerText:
      'Upon tapping the link, recipient will see the following for each organization you’ve chosen to share: organization name; website URL; support type(s) offered; intake contact methods and information (phone, link, email); instructions for abortion seekers to get support; hours of operation (if available); and support organization link on AbortionFinder.org.',
    recordDisplayName: 'Support Organization',
  },
  NavigatorResourceRecord: {
    explainerText:
      'Upon tapping the link, recipient will see the following for each resource you’ve chosen to share: resource name; website URL; service(s) offered; contact methods and information (phone, link, email); hours of operation (if available); and resource description.',
    recordDisplayName: 'Resource',
  },
}

const customStyles = {
  content: {
    width: 'max-content',
    maxWidth: 'calc(min(800px, 100vw - 40px))',
  },
}

const getAllFocusableElements = (parent) => {
  if (!parent) {
    console.warn('You need to pass a parent HTMLElement')
    return [] // Return array so length queries will work
  }

  return parent.querySelectorAll(
    'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)'
  )
}

const expirationText = `${rubyConstants.LinkSharing.EXPIRATION / 3600} hours`
const prefix = 'link_sharing'

const SendToPhoneButton = ({ disabled, recordDisplayName, setIsOpen }) => (
  <TooltipAnchor additionalClassName="SendToPhoneButton" disabled={!disabled}>
    <button
      id="link-sharing"
      className="send-to-phone"
      type="button"
      onClick={() => setIsOpen(true)}
      disabled={disabled}
    >
      Send to Phone
      <img src={sendToPhoneIcon} alt="" />
    </button>
    <Tooltip>
      {`Text a secure link to view ${recordDisplayName} options that you select from the list.`}
    </Tooltip>
  </TooltipAnchor>
)

const LinkSharingForm = ({
  authenticated_api_send_to_phone_path,
  listenToCheckboxes,
  recordIds = [],
  recordOptions,
  recordType,
}) => {
  const { explainerText, recordDisplayName, showLinkSharingFormClinicOptions } =
    recordTypes[recordType]

  const [currentRecordIds, setCurrentRecordIds] = useState(recordIds) // records in the dropdown menu
  const [phoneNumber, setPhoneNumber] = useState('')
  const [chosenUsState, setChosenUsState] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false)
  const [confirmConsentIsChecked, setConfirmConsentIsChecked] = useState(false)
  const [virtualStateModalIsOpen, setVirtualStateModalIsOpen] = useState(false)
  const [locale, setLocale] = useState('en')

  // START Provide keyboard navigation in modal
  const [focusIndex, setFocusIndex] = useState(0)
  const [doneZero, setDoneZero] = useState(false)

  function getNextNonHiddenIndex(elementArr, currentPosition) {
    if (currentPosition >= elementArr.length - 1) {
      return 0
    }
    for (let i = 1; i < elementArr.length - currentPosition + 1; i += 1) {
      if (elementArr[currentPosition + i].type !== 'hidden') {
        return currentPosition + i
      }
    }
    for (let i = 0; i < elementArr.length; i += 1) {
      if (elementArr[i].type !== 'hidden') {
        return i
      }
    }
    return 0
  }

  const getPreviousHiddenIndex = (elementArr, currentPosition) => {
    if (currentPosition === 0) {
      return elementArr.length - 1
    }
    for (let i = currentPosition - 1; i >= 0; i -= 1) {
      if (elementArr[i].type !== 'hidden') {
        return i
      }
    }
    return elementArr.length - 1
  }

  useEffect(() => {
    if (isOpen) {
      const modal = document.getElementsByClassName('ReactModalPortal')[0]
      const allFocusableElements = getAllFocusableElements(modal)
      allFocusableElements[focusIndex].focus()
    }
  }, [focusIndex])

  const keyListener = (e) => {
    const modal = document.getElementsByClassName('ReactModalPortal')[0]
    const allFocusableElements = getAllFocusableElements(modal)
    if (e.keyCode === 9 && isOpen) {
      if (e.shiftKey) {
        setFocusIndex(getPreviousHiddenIndex(allFocusableElements, focusIndex))
      } else if (
        focusIndex === 0 &&
        allFocusableElements[0].type !== 'hidden' &&
        doneZero === false
      ) {
        allFocusableElements[0].focus()
        setDoneZero(true)
      } else {
        setFocusIndex(getNextNonHiddenIndex(allFocusableElements, focusIndex))
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyListener)
    return () => window.removeEventListener('keydown', keyListener)
  }, [keyListener])
  // END Provide keyboard navigation in modal

  const defaultRecordIds = () =>
    listenToCheckboxes ? getCheckedCollectionSelectionIds() : recordIds

  // If this is embedded in a non-react index page, listen to changing checkboxes
  if (listenToCheckboxes) useCollectionSelectionCheckboxes(setCurrentRecordIds)
  // otherwise, if this is embedded in a parent React component, listen to changing checkboxes
  else useEffect(() => setCurrentRecordIds(recordIds), [recordIds])

  const resetAndCloseLinkSharingForm = () => {
    setPhoneNumber('')
    setCurrentRecordIds(defaultRecordIds())
    setIsOpen(false)
    setConfirmConsentIsChecked(false)
  }

  const edits =
    !!phoneNumber.length ||
    JSON.stringify(currentRecordIds.sort()) !==
      JSON.stringify(defaultRecordIds().sort())

  const tryToClose = () =>
    edits ? setCancelModalIsOpen(true) : resetAndCloseLinkSharingForm()

  const sendToPhoneButtonOnIndexPageIsDisabled = !currentRecordIds.length

  const phoneIsGood =
    phoneNumber.replace(/\D/g, '').replace(/^1/, '').length === 10 // remove the leading '1' if present & convert to only digits

  const sendTextButtonWithinModalIsDisabled =
    !confirmConsentIsChecked || !currentRecordIds.length || !phoneIsGood

  const disabledSendTextButtonTooltipText =
    (!phoneIsGood && 'Enter a valid 10 digit phone number') ||
    (!currentRecordIds.length && 'Select at least one provider') ||
    (!confirmConsentIsChecked &&
      'Confirm that you have consent to send a text message')

  const [availableAbortionOfferings, setAvailableOfferings] = useState({})
  useEffect(() => {
    if (!showLinkSharingFormClinicOptions) return
    if (!isOpen) return

    async function fetchAbortionOfferings() {
      const response = await axios
        .post(
          '/internal_api/abortion_offerings',
          { clinic_ids: currentRecordIds },
          { responseType: 'json' }
        )
        .catch((err) => console.warn(err)) // eslint-disable-line no-console
      if (response && response.data) setAvailableOfferings(response.data)
    }
    fetchAbortionOfferings()
  }, [currentRecordIds, isOpen, showLinkSharingFormClinicOptions])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const tryToSubmit = () => {
    if (
      availableAbortionOfferings.telehealth_state_options?.length &&
      !chosenUsState
    ) {
      setVirtualStateModalIsOpen(true)
      return
    }

    setIsSubmitting(true)
    document
      .querySelector(`form[action='${authenticated_api_send_to_phone_path}']`)
      .submit()
  }

  if (isSubmitting) return <LoadinDotsFullScreen />

  return (
    <div id="LinkSharingForm">
      <SendToPhoneButton
        disabled={sendToPhoneButtonOnIndexPageIsDisabled}
        recordDisplayName={recordDisplayName}
        setIsOpen={setIsOpen}
      />
      <GenericModal
        isOpen={isOpen}
        onRequestClose={tryToClose}
        contentLabel="modal label"
        header={`Send ${recordDisplayName}(s) secure link to a phone`}
        closeButtonText={null}
        confirmButtonText={null}
        customStyles={customStyles}
      >
        <div className="LinkSharingFormModal">
          <form
            action={authenticated_api_send_to_phone_path}
            method="post"
            id="LinkSharingForm-form"
          >
            <input
              type="hidden"
              name="authenticity_token"
              value={getCsrfToken()}
            />
            <input
              type="hidden"
              name={`${prefix}[current_href]`}
              value={window.location.href}
            />
            <input
              type="hidden"
              name={`${prefix}[record_type]`}
              value={recordType}
            />
            <input
              type="hidden"
              name={`${prefix}[chosen_us_state]`}
              value={chosenUsState}
            />
            <fieldset className="link-sharing-send-to">
              <OrgPhoneInput
                label="Recipient Phone Number"
                name={`${prefix}[recipient_phone_number]`}
                value={phoneNumber}
                onChange={(newNumber) => setPhoneNumber(newNumber)}
                showErrors={false}
              />
              <OrgSelectInput
                label="Language"
                name={`${prefix}[locale]`}
                onChange={setLocale}
                value={locale}
                options={[
                  ['English', 'en'],
                  ['Spanish', 'es'],
                ]}
              />
            </fieldset>
            <fieldset className="link-sharing-content">
              <OrgSelectInput
                label={`${recordDisplayName} Options (${currentRecordIds.length})`}
                name={`${prefix}[record_ids][]`}
                value={currentRecordIds}
                options={recordOptions}
                isMulti
                onChange={(newValue) => setCurrentRecordIds(newValue)}
                expandsOnOpen={!showLinkSharingFormClinicOptions}
              />
            </fieldset>
            {showLinkSharingFormClinicOptions && (
              <LinkSharingFormClinicOptions
                prefix={prefix}
                availableAbortionOfferings={availableAbortionOfferings}
              />
            )}
            <div className="subheader_2">
              Your text message will include a secure link that expires after{' '}
              {expirationText}.
            </div>
            <div className="explainer">{explainerText}</div>
            <fieldset className="consent">
              <label htmlFor="confirm-consent-checkbox">
                <input
                  type="checkbox"
                  checked={confirmConsentIsChecked}
                  id="confirm-consent-checkbox"
                  onChange={(e) => setConfirmConsentIsChecked(e.target.checked)}
                />
                I confirm that the recipient has given me consent (verbally or
                in writing) to receive this text message.
              </label>
            </fieldset>
            <div className="buttons">
              <button
                type="button"
                className="cancel"
                aria-label="Close modal"
                onClick={tryToClose}
              >
                Cancel
              </button>
              <TooltipAnchor
                additionalClassName="sendTextButtonTooltip"
                disabled={!sendTextButtonWithinModalIsDisabled}
              >
                <button
                  type="button"
                  onClick={tryToSubmit}
                  disabled={sendTextButtonWithinModalIsDisabled}
                  id="send-text-button"
                >
                  Send Text
                </button>
                <Tooltip> {disabledSendTextButtonTooltipText} </Tooltip>
              </TooltipAnchor>
            </div>
          </form>
        </div>
      </GenericModal>
      <GenericModal
        header="Are you sure?"
        closeButtonText="No, Keep Editing"
        confirmButtonText="Yes, Discard Edits"
        isOpen={cancelModalIsOpen}
        onRequestClose={() => setCancelModalIsOpen(false)}
        onConfirm={() => {
          setCancelModalIsOpen(false)
          resetAndCloseLinkSharingForm()
        }}
      >
        <p>
          <b>There are unsaved edits on this screen</b>
          <br />
          Do you want to discard these edits?
        </p>
      </GenericModal>
      <GenericModal
        header="Select virtual provider state"
        closeButtonText="Cancel"
        confirmButtonText="Send Text"
        isOpen={virtualStateModalIsOpen}
        onRequestClose={() => {
          setVirtualStateModalIsOpen(false)
          setChosenUsState('')
        }}
        onConfirm={() => tryToSubmit()}
      >
        <div className="virtual-provider-choose-us-state-modal">
          <div className="virtual-state-modal-explainer">
            {availableAbortionOfferings.explainer_text}
          </div>
          <OrgSelectInput
            label="State Served"
            value={chosenUsState}
            expandsOnOpen
            expandsOnOpenMargin={`${Math.min(
              (availableAbortionOfferings.telehealth_state_options?.length ||
                1) *
                30 +
                10,
              250
            )}px`}
            onChange={(val) => setChosenUsState(val)}
            options={(availableAbortionOfferings.telehealth_state_options || [])
              .sort((a, b) => usStates[a].localeCompare(usStates[b]))
              .map((abbrev) => [usStates[abbrev], abbrev])}
          />
        </div>
      </GenericModal>
    </div>
  )
}

LinkSharingForm.propTypes = {
  authenticated_api_send_to_phone_path: PropTypes.string.isRequired,
  listenToCheckboxes: PropTypes.bool,
  recordIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  recordOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  recordType: PropTypes.oneOf(Object.keys(recordTypes)).isRequired,
}

LinkSharingForm.defaultProps = {
  listenToCheckboxes: false,
}

export default LinkSharingForm
