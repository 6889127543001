import { IndexState } from '../../shared/index_pages/IndexState.js'

export class NavigatorClinicIndexState extends IndexState {
  constructor({ isMapView, clickedId, ...rest }) {
    super(rest)
    this.isMapView = isMapView
    this.clickedId = clickedId
  }

  static setMapView(isShown) {
    return (oldState) => {
      const newState = oldState._clone({
        isMapView: isShown,
      })
      return newState
    }
  }

  static setClickedId(clinicId) {
    return (oldState) => {
      const newState = oldState._clone({
        clickedId: clinicId,
      })
      return newState
    }
  }

  //
  //    Private methods
  //

  // modifies NavigatorClinicIndexState in-place
  _adjustFromFilterServerData(_oldState, serverData) {
    // this.currentOrder = this.constructor.adjustOrder(
    //   this,
    //   serverData.current_filters,
    //   serverData
    // )
  }

  static adjustOrder(indexState, current_filters, responseData) {
    //   let newOrder = indexState.currentOrder
    //   // when filtering by location:
    //   if (responseData.location_query_lng_lat) {
    //     newOrder ||= 'distance_asc'
    //     // when not filtering by location, don't allow distance or drive-time order
    //   } else if (['distance_asc', 'drive_time_asc'].includes(newOrder)) {
    //     newOrder = ''
    //     // when filtering by mapped_area:
    //   } else if (current_filters.some((filter) => filter.key === 'mapped_area')) {
    //     newOrder ||= 'nearest_airport_asc'
    //   }
    //   return newOrder
  }
}
