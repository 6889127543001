import React, { useState, useEffect, useRef, useMemo } from 'react'
import axios from 'axios'
import qs from 'qs'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import * as Ariakit from '@ariakit/react'
import LinkSharingForm from '../../shared/link_sharing/LinkSharingForm.jsx'
import NavigatorClinicTable, { clinicType } from './NavigatorClinicTable.jsx'
import IndexFilters from '../../shared/index_pages/IndexFilters.jsx'
import IndexScopeLinks, {
  scopesPropType,
} from '../../shared/index_pages/IndexScopesLinks.jsx'
import PaginationLinks from '../../shared/index_pages/PaginationLinks.jsx'
import IndexOrderLinks from '../../shared/index_pages/IndexOrderLinks.jsx'
import {
  useIndexStateSetup,
  perPage,
} from '../../shared/index_pages/IndexState'
import { NavigatorClinicIndexState } from './NavigatorClinicIndexState.js'
import { fetchResultsFromServer } from '../../shared/index_pages/fetch_results_from_server.js'
import NavigatorClinicMapView from './NavigatorClinicMapView.jsx'
import { useIndexFilters } from '../../shared/useIndexFilters.js'
import { useIndexUrlParams } from '../../shared/useIndexUrlParams.js'
import {
  navigatorClinicTopFilters,
  navigatorClinicFilterDrawers,
  orderItemsConstructor,
} from './navigator_clinic_index_filters_and_order_items.js'
import ServerErrorMessage from '../../shared/index_pages/ServerErrorMessage.jsx'
import './NavigatorClinicIndex.scss'

const driveTimeUrl = '/authenticated_api/navigator_clinic_drive_times'
const nullDriveTimesText =
  "Drive time couldn't be calculated. Results are sorted by closest distance from your search location."
const recordType = 'NavigatorClinic'

const formatDriveTime = (minutes) => {
  if (!minutes) return ''
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  let formattedTime = ''

  if (hours) {
    formattedTime += `${hours} hour${hours > 1 ? 's' : ''} `
  }
  if (remainingMinutes) {
    formattedTime += `${remainingMinutes} min.`
  }

  return `${formattedTime.trim()} drive`
}

export const clearFiltersIfLocationLmpChanged = (oldFilters, newFilters) => {
  // When 'location' or 'pregnancy_stage_days' changes:
  if (
    oldFilters.pregnancy_stage_days !== newFilters.pregnancy_stage_days ||
    oldFilters.location !== newFilters.location
  ) {
    // clear other filters
    return Object.fromEntries(
      Object.entries(newFilters).filter(([key]) =>
        ['location', 'pregnancy_stage_days'].includes(key)
      )
    )
  }
  return newFilters
}

// Main Component: NavigatorClinicIndex
const NavigatorClinicIndex = ({
  all_clinics,
  authenticated_api_send_to_phone_path,
  filter_drawer_options,
  google_api_key,
  initial_clicked_id,
  initial_filters,
  initial_mapview,
  initial_order,
  initial_page,
  initial_scope,
  is_staging_environment,
  scopes,
  on_starred_page = false,
}) => {
  const [showTelehealthHiddenCard, setShowTelehealthHidden] = useState(false)

  // loading the map is slow. We want to preload it, but only after the initial render
  const [preloadMapView, setPreloadMapView] = useState(false)
  useEffect(() => setPreloadMapView(true), [])

  const [scopeToUsStates, setScopeToUsStates] = useState([])
  const [mapNeedsRecenter, setMapNeedsRecenter] = useState(true)

  // distances will be returned by the server and change based on location search
  const [locationQueryLngLat, setLocationQueryLngLat] = useState(null)
  const [distances, setDistances] = useState({})
  const [driveTimes, setDriveTimes] = useState({})

  const isUsStateDisabled = useMemo(() => {
    const enabledUsStates = new Set(
      Object.values(all_clinics).flatMap((c) => c.states_served)
    )
    return (state_abbrev) => !enabledUsStates.has(state_abbrev)
  }, [all_clinics])

  const recordOptionsForLinkSharingForm = useMemo(
    () => Object.values(all_clinics).map((c) => [c.formatted_name, c.id]),
    [all_clinics]
  )

  const [indexState, setIndexState] = NavigatorClinicIndexState.use({
    hashOfRecords: all_clinics,
    orderItems: [], // will be added by useEffect below
    filterQueryParams: initial_filters,
    currentPage: initial_page,
    currentScope: initial_scope,
    currentOrder: initial_order,
    clickedId: initial_clicked_id,
    isMapView: initial_mapview,
    recordType,
    scopes,
    defaultOrderSortValFunc: (id) => {
      switch (all_clinics[id]?.location_type) {
        case 'telehealth_only':
          return 0
        case 'hybrid':
          return 1
        default:
          return 2
      }
    },
  })

  const orderedScopedFilteredRecordsWithDistances =
    indexState.orderedScopedFilteredIds.map((id) => ({
      ...all_clinics[id],
      distance: distances[id],
      driveTime: formatDriveTime(driveTimes[id]),
    }))

  let showVirtualProvidersHiddenWarning = false
  const recordsForTable = orderedScopedFilteredRecordsWithDistances.filter(
    (record) => {
      if (!indexState.paginatedOrderedScopedFilteredIds.has(record.id))
        return false
      if (indexState.currentOrder === 'drive_time_asc' && !record.lat) {
        showVirtualProvidersHiddenWarning = true
        return false
      }
      return true
    }
  )

  /* Improve performance not updating map when on list view */
  const recordsForMapView = useRef([])
  if (indexState.isMapView)
    recordsForMapView.current =
      orderedScopedFilteredRecordsWithDistances.filter((x) => x.lat && x.lng)

  const setPageToContainId = (id) => {
    // Find the index of the given id in orderedScopedFilteredIds
    const index = indexState.orderedScopedFilteredIds.indexOf(id)
    if (index === -1) {
      console.warn(`ID ${id} not found in orderedScopedFilteredIds.`)
      return
    }
    // Calculate the page number this index falls into
    const pageNumber = Math.floor(index / perPage) + 1

    // Set the currentPage state to the calculated page number
    setIndexState(NavigatorClinicIndexState.setPage(pageNumber))
  }

  useEffect(() => {
    if (!locationQueryLngLat) {
      setDriveTimes({})
      return
    }

    const refreshDriveTimes = async () => {
      try {
        const [lng, lat] = locationQueryLngLat
        const qstring = qs.stringify({ lng, lat })
        const response = await axios.get(`${driveTimeUrl}.json?${qstring}`)

        setDriveTimes(response.data || {})
      } catch (err) {
        console.warn(err)
      }
    }

    refreshDriveTimes()
  }, [locationQueryLngLat])

  const handleMapView = (newMapView) => {
    setIndexState(NavigatorClinicIndexState.setMapView(newMapView))
  }

  const mapViewToggle = (
    <div className="mapViewToggle">
      <button
        type="button"
        onClick={() => handleMapView(false)}
        className={indexState.isMapView ? '' : 'active'}
      >
        List
      </button>
      <button
        type="button"
        onClick={() => handleMapView(true)}
        className={indexState.isMapView ? 'active' : ''}
      >
        Map
      </button>
    </div>
  )

  // Show telehealth warning when we're hiding telehealth clinics on the map
  useEffect(
    () =>
      setShowTelehealthHidden(
        orderedScopedFilteredRecordsWithDistances.some((record) => !record.lat)
      ),
    [
      orderedScopedFilteredRecordsWithDistances
        .map((r) => r.id)
        .sort()
        .join(','),
    ] // convert to a string to not trigger useEffect if we have a new array object with the same values
  )

  const handleApplyFilters = async (newFilters, prevFilters, isFirstRender) => {
    const newerFilters = clearFiltersIfLocationLmpChanged(
      prevFilters,
      newFilters
    )
    try {
      const responseData = await fetchResultsFromServer(
        indexState.baseUrl(),
        newerFilters
      )
      setIndexState(
        NavigatorClinicIndexState.handleServerData(responseData, isFirstRender)
      )

      // maybe change the order based on new filters
      let newOrder = indexState.currentOrder

      // when filtering by location:
      if (responseData.location_query_lng_lat) {
        newOrder ||= 'distance_asc'

        // when not filtering by location, don't allow distance or drive-time order
      } else if (['distance_asc', 'drive_time_asc'].includes(newOrder)) {
        newOrder = ''

        // when filtering by mapped_area:
      } else if (newFilters.mapped_area) {
        newOrder ||= 'nearest_airport_asc'
      }
      setIndexState(NavigatorClinicIndexState.setOrder(newOrder))

      setScopeToUsStates(responseData.scope_to_us_states || [])
      setDistances(responseData.distances || {})
      setLocationQueryLngLat(responseData.location_query_lng_lat || null)
    } catch (error) {
      setIndexState(NavigatorClinicIndexState.setErrorMessage(error.message))
    }

    // recenter map, but not if the change was adding or changing mapped_area
    const mappedAreaWasRemoved =
      prevFilters.mapped_area && !newerFilters.mapped_area
    const filtersForCompare = [{ ...prevFilters }, { ...newerFilters }]
    filtersForCompare.forEach((obj) => delete obj.mapped_area) // eslint-disable-line no-param-reassign
    const nonMapfiltersChanged = !isEqual(...filtersForCompare)
    if (mappedAreaWasRemoved || nonMapfiltersChanged) setMapNeedsRecenter(true)
    // NOTE: for performance, the mapview will not be updated if we are on listview
    // thus, we need to preserve mapNeedsRecenter=true until we switch to mapView and do that recenter
    // also, if this isFirstRender (with an unfiltered list of clinics), we preserve mapNeedsRecenter=true
    // until we get the filtered list from the server
    // at which point (where isFirstRender != true) we can set mapNeedsRecenter to false
    else if (!isFirstRender && indexState.isMapView) setMapNeedsRecenter(false)
  }

  const {
    appliedFilters,
    applyFilters,
    removeAllFilters,
    removeFilter,
    setUnappliedFilters,
    unappliedFilters,
  } = useIndexFilters(initial_filters, handleApplyFilters)

  useIndexStateSetup(indexState)

  useIndexUrlParams(indexState.baseUrl(), {
    clicked_id: indexState.clickedId,
    mapview: indexState.isMapView,
    order: indexState.currentOrder,
    page: indexState.currentPage,
    q: appliedFilters,
    scope: indexState.currentScope,
  })

  useEffect(
    () =>
      setIndexState(
        NavigatorClinicIndexState.setOrderItems(
          orderItemsConstructor({
            all_clinics,
            distances,
            driveTimes,
            disableLocationBasedSorts: !Array.isArray(locationQueryLngLat),
            scopeToUsStates,
          })
        )
      ),
    [distances, driveTimes, scopeToUsStates]
  )

  const [showDriveTimeTooltip, setShowDriveTimeTooltip] = useState(false)
  if (
    indexState.currentOrder === 'drive_time_asc' &&
    Object.keys(driveTimes || {}).length &&
    indexState.orderedScopedFilteredIds.length > 0 &&
    !indexState.orderedScopedFilteredIds.some(
      (id) => typeof driveTimes[id] === 'number'
    )
  ) {
    setIndexState(NavigatorClinicIndexState.setOrder('distance_asc'))
    setShowDriveTimeTooltip(true)
  }

  const setSelectedIds = (setOfIds) =>
    setIndexState(NavigatorClinicIndexState.setSelectedIds(setOfIds))
  const setSelectedId = (...args) =>
    setIndexState(NavigatorClinicIndexState.setSelectedId(...args))

  const totalRecords = indexState.totalRecords()

  // Starred page uses this but without filters
  const showFiltersAndSearch = Object.keys(filter_drawer_options).length > 0

  return (
    <div id="NavigatorClinicIndex">
      {on_starred_page ? <h2> Providers </h2> : <h1> Providers </h1>}
      {indexState.errorMessage && (
        <ServerErrorMessage
          message={indexState.errorMessage}
          filterQueryParams={appliedFilters}
          clearFilters={() => applyFilters({})}
        />
      )}

      {showFiltersAndSearch && (
        <IndexFilters
          appliedFilters={appliedFilters}
          applyFilters={applyFilters}
          currentFiltersFromServer={indexState.filterDescriptions}
          currentPage={indexState.currentPage}
          drawerFilters={navigatorClinicFilterDrawers(
            filter_drawer_options,
            isUsStateDisabled
          )}
          filterDrawerOptions={filter_drawer_options}
          filterErrors={indexState.filterErrors}
          removeAllFilters={removeAllFilters}
          removeFilter={removeFilter}
          setUnappliedFilters={setUnappliedFilters}
          topFilters={navigatorClinicTopFilters}
          unappliedFilters={unappliedFilters}
        />
      )}

      <Ariakit.PopoverProvider animate={true}>
        <div className="scopes-toggles-widgets-sorting">
          <div className="scopes">
            <IndexScopeLinks
              scopes={scopes}
              currentScope={indexState.currentScope}
              handleNewScope={(scope) => {
                setIndexState(NavigatorClinicIndexState.setScope(scope))
                setMapNeedsRecenter(true)
              }}
              filteredIds={indexState.filteredIds}
              unscopedLabel="All Providers"
            />
          </div>

          <div className="toggles">
            <Ariakit.Popover
              className="null-drive-times popover"
              key={showDriveTimeTooltip}
              open={showDriveTimeTooltip}
              onClose={() => setShowDriveTimeTooltip(false)}
              hideOnInteractOutside
              focusable={false}
            >
              <Ariakit.PopoverArrow className="arrow" />
              <Ariakit.PopoverHeading className="heading">
                {nullDriveTimesText}
              </Ariakit.PopoverHeading>
            </Ariakit.Popover>
            {!on_starred_page && mapViewToggle}
          </div>

          <div className="widgets">
            {!on_starred_page &&
              indexState.paginatedOrderedScopedFilteredIds.size > 0 && (
                <LinkSharingForm
                  recordIds={[
                    ...indexState.paginatedOrderedScopedFilteredIds,
                  ].filter((id) => indexState.selectedIds.has(id))}
                  recordOptions={recordOptionsForLinkSharingForm}
                  recordType={recordType}
                  authenticated_api_send_to_phone_path={
                    authenticated_api_send_to_phone_path
                  }
                />
              )}
          </div>

          <div className="sorting">
            <Ariakit.PopoverAnchor
              className="anchor"
              open={showDriveTimeTooltip}
              disabled={!showDriveTimeTooltip}
            >
              <IndexOrderLinks
                orderItems={indexState.orderItems}
                currentOrder={indexState.currentOrder}
                setCurrentOrder={(or) =>
                  setIndexState(NavigatorClinicIndexState.setOrder(or))
                }
                hasLocationQuery={Array.isArray(locationQueryLngLat)}
              />
            </Ariakit.PopoverAnchor>
          </div>
        </div>
      </Ariakit.PopoverProvider>

      <div className="map-or-table-container">
        <div style={indexState.isMapView ? { display: 'none' } : undefined}>
          {showVirtualProvidersHiddenWarning && (
            <div className="virtual-providers-hidden-warning">
              Virtual providers are hidden because you’ve sorted by shortest
              drive time. Sort by “Distance (default)” to see virtual providers.
            </div>
          )}

          <NavigatorClinicTable
            allowSorting={!Array.isArray(locationQueryLngLat)}
            appointment_availability_filter={
              appliedFilters.appointment_availability
            }
            currentOrder={indexState.currentOrder}
            records={recordsForTable}
            scopeToUsStates={scopeToUsStates}
            selectedIds={indexState.selectedIds}
            setSelectedIds={setSelectedIds}
            onStarredPage={on_starred_page}
          />
        </div>

        {!on_starred_page && (indexState.isMapView || preloadMapView) && (
          <div style={indexState.isMapView ? undefined : { display: 'none' }}>
            <NavigatorClinicMapView
              records={recordsForMapView.current}
              paginatedIds={indexState.paginatedOrderedScopedFilteredIds}
              selectedIds={indexState.selectedIds}
              setSelectedId={setSelectedId}
              google_api_key={google_api_key}
              setPageToContainId={setPageToContainId}
              appointment_availability_filter={
                appliedFilters.appointment_availability
              }
              mappedAreaFilter={appliedFilters.mapped_area}
              setMappedAreaFilter={(newMapArea) =>
                applyFilters({
                  ...appliedFilters,
                  mapped_area: newMapArea,
                })
              }
              mapNeedsRecenter={indexState.isMapView && mapNeedsRecenter}
              setMapView={handleMapView}
              clickedId={indexState.clickedId}
              setClickedId={(id) =>
                setIndexState(NavigatorClinicIndexState.setClickedId(id))
              }
              showTelehealthHiddenCard={showTelehealthHiddenCard}
              setShowTelehealthHidden={setShowTelehealthHidden}
              totalRecords={totalRecords}
              isStagingEnvironment={is_staging_environment}
            />
          </div>
        )}
        <PaginationLinks
          currentPage={indexState.currentPage}
          onPageChange={(page) =>
            setIndexState(NavigatorClinicIndexState.setPage(page))
          }
          perPage={indexState.isMapView ? 9999 : perPage}
          recordType={recordType}
          totalRecords={
            indexState.isMapView
              ? recordsForMapView.current.length
              : totalRecords
          }
        />
      </div>
    </div>
  )
}

NavigatorClinicIndex.defaultProps = {
  initial_filters: {},
  initial_order: '',
  initial_page: 1,
  initial_scope: '',
  initial_mapview: false,
}

const filterDrawerOptionsPropType = PropTypes.shape({
  languages: PropTypes.arrayOf(PropTypes.string),
  non_abortion_services: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
  ),
  service_features: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
})

NavigatorClinicIndex.propTypes = {
  all_clinics: PropTypes.objectOf(clinicType).isRequired,
  authenticated_api_send_to_phone_path: PropTypes.string.isRequired,
  google_api_key: PropTypes.string.isRequired,
  filter_drawer_options: filterDrawerOptionsPropType.isRequired,
  initial_filters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  initial_order: PropTypes.string,
  initial_page: PropTypes.number,
  initial_scope: PropTypes.string,
  initial_mapview: PropTypes.bool,
  scopes: scopesPropType.isRequired,
}

export default NavigatorClinicIndex
