import React from 'react'
import PropTypes from 'prop-types'
import FilterDrawers from './FilterDrawers.jsx'
import CurrentFilters from './CurrentFilters.jsx'
import { TooltipAnchor, Tooltip } from '../Tooltip.jsx'
import { isBlank } from '../../utilities/utilities.ts'

import './IndexFilters.scss'

const IndexFilters = ({
  applyFilters,
  currentFiltersFromServer,
  drawerFilters,
  filterErrors,
  appliedFilters,
  removeFilter,
  topFilters,
  unappliedFilters,
  setUnappliedFilters,
}) => {
  const handleSearchOnKeyDownEnter = (e) => {
    if (e.key !== 'Enter') return
    applyFilters(unappliedFilters)
    e.target.blur()
  }

  // currently, this is the only error we are handling
  const topFilterError = filterErrors?.location

  const searchButtonDisabled = [appliedFilters, unappliedFilters].every(isBlank)

  return (
    <div id="IndexFilters">
      <div className="top-container">
        <div className="filters-and-submit">
          {topFilters.map(
            ({
              label,
              key,
              InputComponent,
              tooltipTextIfDisabled,
              extraProps,
            }) => {
              return (
                <TooltipAnchor
                  key={key}
                  placement="bottom"
                  disabled={!tooltipTextIfDisabled || !extraProps.disabled}
                >
                  <InputComponent
                    key={key}
                    onChange={(newValue) =>
                      setUnappliedFilters((prev) => ({
                        ...prev,
                        [key]: newValue,
                      }))
                    }
                    onKeyDown={handleSearchOnKeyDownEnter}
                    label={label}
                    value={unappliedFilters[key]}
                    {...extraProps}
                  />
                  <Tooltip>{tooltipTextIfDisabled}</Tooltip>
                </TooltipAnchor>
              )
            }
          )}
          <button
            disabled={searchButtonDisabled}
            onClick={() => applyFilters(unappliedFilters)}
            type="button"
          >
            Search
          </button>
        </div>
        {!!topFilterError && <div className="errors">{topFilterError}</div>}
      </div>
      {drawerFilters?.length > 0 && (
        <FilterDrawers
          appliedFilters={appliedFilters}
          applyFilters={applyFilters}
          drawers={drawerFilters}
        />
      )}
      <CurrentFilters
        removeFilter={removeFilter}
        currentFilters={currentFiltersFromServer}
        removeAllFilters={() => applyFilters({})}
      />
    </div>
  )
}

IndexFilters.defaultProps = { topFilterError: '' }

IndexFilters.propTypes = {
  currentFiltersFromServer: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      displayValue: PropTypes.string,
    })
  ).isRequired,
  topFilterError: PropTypes.string,
  appliedFilters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  applyFilters: PropTypes.func.isRequired,
}

export default IndexFilters
