import { useEffect, useState } from 'react'
import { queryString } from './index_pages/fetch_results_from_server.js'
import { objectCompactBlank } from '../utilities/utilities.ts'

const compactQueryString = (params) => queryString(objectCompactBlank(params))

const pushUrlHistory = (baseUrl, params) => {
  const url = `${baseUrl}?${compactQueryString(params)}`
  window.history.pushState({ url }, '', url)
}

export const useIndexUrlParams = (baseUrl, params) => {
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false)
      return
    }

    pushUrlHistory(baseUrl, params)
  }, [compactQueryString(params)])
}
