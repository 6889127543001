import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { TooltipAnchor, Tooltip } from '../../shared/Tooltip.jsx'
import { getCsrfToken } from '../../utilities/utilities.ts'
import GenericModal from '../../shared/GenericModal.jsx'
import AppointmentAvailabilityInput from '../clinics/AppointmentAvailabilityInput.jsx'
import checkBW from '../../../assets/images/check_bw.svg'
import { humanizeString } from '../../utilities/humanize_string.js'
import './AppointmentAvailabilityPills.scss'

// Make the modal not expand/try to scroll when a dropdown menu opens
const customModalStyles = {
  content: {
    overflow: 'visible',
    overflowY: 'visible',
  },
}

// Return a modal form where Navigators can change how soon appointments are available at clinics.
const AppointmentAvailabilityModal = ({
  apptAvailabilityValue,
  clinic,
  handleModalRequestClose,
  modalStatus,
  setApptAvailabilityValue,
  setModalStatus,
}) => {
  const [serverErrors, setServerErrors] = useState({})

  const modalBody = () => {
    if (modalStatus === 'success')
      return (
        <div className="appointment-availability-submit-response">
          <img src={checkBW} alt="checkmark" />
          <h4>Your update for appointment availability has been accepted</h4>
          <button type="button" onClick={handleModalRequestClose}>
            Close
          </button>
        </div>
      )

    if (modalStatus === 'failure')
      return (
        <div className="appointment-availability-submit-response">
          <h4>Could not update record. Contact us for help.</h4>
          <button type="button" onClick={handleModalRequestClose}>
            Close
          </button>
        </div>
      )

    return (
      <div className="AppointmentAvailabilityPills-modal-form">
        {Object.keys(clinic.appointment_availability_text_hash).map((key) => (
          <fieldset key={key} aria-labelledby={`fieldsetLabel-${key}`}>
            <div className="label" id={`fieldsetLabel-${key}`}>
              {humanizeString(key)}
            </div>
            <div>
              <AppointmentAvailabilityInput
                prefix={key}
                onChange={(newValue) =>
                  setApptAvailabilityValue((prev) => ({
                    ...prev,
                    [key]: newValue,
                  }))
                }
                value={apptAvailabilityValue[key]}
                show_no_appointment_required_checkbox={false}
              />
              <div className="errors">
                {serverErrors[`clinic.appointment_availability_${key}`]}
              </div>
            </div>
          </fieldset>
        ))}
      </div>
    )
  }

  const updateRecord = async () => {
    setModalStatus('submitting')
    axios.defaults.headers.common['X-CSRF-Token'] = getCsrfToken()
    axios.defaults.headers.common['Accept'] = 'application/json'
    try {
      const response = await axios.patch(
        `/navigator_clinics/${clinic.id}/update_via_api`,
        {
          navigator_clinic: {
            clinic_attributes: {
              appointment_availability: apptAvailabilityValue,
            },
          },
        },
        {
          validateStatus: (code) => !![200, 422].includes(code),
          // handle 422 as an expected error (rails model errors)
        }
      )
      if (response.data.status === 'success') setModalStatus('success')

      if (response.data.status === 'success') {
        setModalStatus('success')
        return
      }

      setServerErrors(response.data.errors)
      if (
        Object.keys(response.data.errors || {}).some(
          (x) => !x.startsWith('clinic.appointment_availability_')
        )
      )
        setModalStatus('failure')
      else setModalStatus('form')
    } catch (error) {
      console.error('Error updating record:', error)
    }
  }

  return (
    <GenericModal
      isOpen={!!modalStatus}
      onRequestClose={handleModalRequestClose}
      closeButtonText={modalStatus === 'form' && 'Cancel'}
      confirmButtonText={modalStatus === 'form' && 'Submit'}
      onConfirm={modalStatus === 'form' ? updateRecord : undefined}
      header={
        modalStatus === 'form' &&
        'Update appointment availability time estimates'
      }
      customStyles={customModalStyles}
    >
      {modalBody()}
    </GenericModal>
  )
}

// Return a button (although styled like a link) saying "Submit Availability". Clicking
// on the button/link opens the AppointmentAvailabilityModal
export const SubmitAvailabilityLink = ({ clinic, linkText }) => {
  const [modalStatus, setModalStatus] = useState(null)

  const hash = Object.fromEntries(
    Object.entries(clinic.appointment_availability_text_hash).map(
      ([key, { lower_range, upper_range }]) => [
        key,
        { lower_range: lower_range || '', upper_range: upper_range || '' },
      ]
    )
  )

  const [apptAvailabilityValue, setApptAvailabilityValue] = useState(hash)
  const handleModalRequestClose = () => {
    if (modalStatus === 'success') window.location.reload()
    setModalStatus(null)
    // reset to original value on modal close
    setApptAvailabilityValue(hash)
  }

  return (
    <span className="SubmitAvailabilityLink">
      <button
        className="availability-button"
        type="button"
        onClick={() => setModalStatus('form')}
      >
        {linkText}
      </button>
      <AppointmentAvailabilityModal
        apptAvailabilityValue={apptAvailabilityValue}
        clinic={clinic}
        handleModalRequestClose={handleModalRequestClose}
        modalStatus={modalStatus}
        setApptAvailabilityValue={setApptAvailabilityValue}
        setModalStatus={setModalStatus}
      />
    </span>
  )
}

// Return a *single* pill-shaped widget, e.g. "( Abortion Pill: 1 - 3 weeks )"
export const AppointmentAvailabilityPill = ({
  clinic,
  appointmentTypeKey, // "abortion_pill"
  showLabel, // e.g. "Abortion Pill: 1 - 3 weeks" or "1 - 3 weeks"
  tooltipDisabled,
}) => {
  const apptAvailabilityTextHash =
    clinic.appointment_availability_text_hash[appointmentTypeKey]

  return (
    <TooltipAnchor disabled={tooltipDisabled}>
      <span
        className={`AppointmentAvailabilityPill pill ${apptAvailabilityTextHash.css_class}`}
        id="update-availability"
      >
        {showLabel && `${humanizeString(appointmentTypeKey, 'capitalize')}: `}
        {apptAvailabilityTextHash.text || 'Unknown'}
      </span>
      <Tooltip>
        <SubmitAvailabilityLink
          clinic={clinic}
          linkText="Submit update to availability"
        />
      </Tooltip>
    </TooltipAnchor>
  )
}
AppointmentAvailabilityPill.propTypes = {
  clinic: PropTypes.shape({
    appointment_availability_text_hash: PropTypes.objectOf(
      PropTypes.shape({
        available: PropTypes.bool,
        css_class: PropTypes.string,
        lower_range: PropTypes.string,
        text: PropTypes.string,
        upper_range: PropTypes.string,
      })
    ).isRequired,
    appointment_availability_verified_at: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
  appointmentTypeKey: PropTypes.oneOf([
    'counseling',
    'abortion_pill',
    'abortion_procedure',
    'telehealth_pill',
  ]).isRequired,
  showLabel: PropTypes.bool,
}
AppointmentAvailabilityPill.defaultProps = {
  showLabel: true,
}

// Return true when filter asks for appt availability earlier than X, and this clinic's
// known availability does not work, but there is an unknown availability that *might*
// work.
const clinicMaySatisfyFilterDueToUnknown = (
  clinic,
  appointment_availability_filter
) => {
  const filteringShouldIncludeUnknowns =
    appointment_availability_filter &&
    appointment_availability_filter.exclude_unknowns !== true &&
    appointment_availability_filter.exclude_unknowns !== 'true'

  const someApptTypeSatisfiesFilter = Object.values(
    clinic.appointment_availability_text_hash
  ).some(
    (v) =>
      parseInt(v.lower_range) < parseInt(appointment_availability_filter?.weeks)
  )

  return filteringShouldIncludeUnknowns && !someApptTypeSatisfiesFilter
}

// Return a set of Pill-shaped widgets, for all the appointment types that a clinic offers.
// Also includes the date they were last updated.
const AppointmentAvailabilityPills = ({
  clinic,
  appointment_availability_filter,
  tooltipDisabled,
}) => {
  const showUnknowns = clinicMaySatisfyFilterDueToUnknown(
    clinic,
    appointment_availability_filter
  )

  // Should we remove appointment types where the availability is unknown?
  const filteredAppointmentTypes = Object.entries(
    clinic.appointment_availability_text_hash
  ).filter(([_apptType, subHash]) => subHash.text || showUnknowns)

  const somePills = !!filteredAppointmentTypes.length

  const button = (
    <SubmitAvailabilityLink linkText="Submit Availability" clinic={clinic} />
  )

  const pillContent = () => (
    <div className="content">
      {filteredAppointmentTypes.map(([subHashKey, _subHash]) => (
        <AppointmentAvailabilityPill
          appointmentTypeKey={subHashKey}
          clinic={clinic}
          key={subHashKey}
          tooltipDisabled={tooltipDisabled}
        />
      ))}
      {somePills && clinic.appointment_availability_verified_at && (
        <LastUpdatedAtDate date={clinic.appointment_availability_verified_at} />
      )}
    </div>
  )

  return (
    <div className="AppointmentAvailabilityPills">
      {somePills ? pillContent() : button}
    </div>
  )
}

export const LastUpdatedAtDate = ({ date }) => (
  <div className="LastUpdatedAtDate">
    Last Updated:{' ' /* this space is intentional */}
    {new Date(date).toLocaleDateString('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })}
  </div>
)

AppointmentAvailabilityPills.propTypes = {
  clinic: PropTypes.shape({
    appointment_availability_text_hash: PropTypes.objectOf(
      PropTypes.shape({
        available: PropTypes.bool,
        css_class: PropTypes.string,
        lower_range: PropTypes.string,
        text: PropTypes.string,
        upper_range: PropTypes.string,
      })
    ).isRequired,
    appointment_availability_verified_at: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default AppointmentAvailabilityPills
