import { useEffect, useState } from 'react'
import { objectCompactBlank } from '../utilities/utilities.ts'

function removeKeysFromObject(obj, keysToRemove) {
  let newObject = { ...obj }

  for (const key of [keysToRemove].flat()) {
    const { [key]: _, ...rest } = newObject // eslint-disable-line no-unused-vars
    newObject = rest
  }

  return newObject
}

export const useIndexFilters = (initial_filters, handleApplyFilters) => {
  const [appliedFilters, setAppliedFilters] = useState(initial_filters)
  const [unappliedFilters, setUnappliedFilters] = useState(initial_filters)

  const applyFilters = async (unprocessedNewFilters) => {
    // remove blank values from filters (empty arrays, empty strings, etc.)
    const newFilters = objectCompactBlank(unprocessedNewFilters)
    await handleApplyFilters(newFilters, appliedFilters)
    setUnappliedFilters(newFilters)
    setAppliedFilters(newFilters)
  }

  // apply initial filters on first load
  useEffect(() => {
    handleApplyFilters(initial_filters, initial_filters, true)
  }, [])

  const removeFilter = (key) => {
    const updatedFilters = removeKeysFromObject(appliedFilters, key)

    applyFilters(updatedFilters)
  }

  return {
    appliedFilters,
    applyFilters,
    removeFilter,
    setAppliedFilters,
    setUnappliedFilters,
    unappliedFilters,
  }
}
